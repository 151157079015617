
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import { DataEmitterService } from '@/services/DataEmiterService'

export default {

  components: {
  },
  data() {
    return {
      disableRating: false,
      submitting: false,
      ratings: null,
      showLegend: false,
      currentApplication: null,
      subPage: 'candidateList',
      search: '',
      items: [],
      page: 1,
      selectedCategory: 'ProductsCustomization',
      categories: [ 'All'],
      totalPages: 0,
      totalElements: 0,
      itemsPerPage: 10,
      areaFilter: 'All',
      expandedDescription: false,
      expandedPdf: false,
      expanded3D: false,
      movingSliderName: '',
      sliderStartX: -1,
      sliderStartValue: -1,
      rateCriterias: [
        {
          label: 'Innovation in shapes',
          fieldName: 'innovationInShapes',
        },
        {
          label: 'Functionality',
          fieldName: 'functionality',
        },
        {
          label: 'Sustainability',
          fieldName: 'sustainability',
        },
        {
          label: 'Technical feasibility',
          fieldName: 'technicalFeasibility',
        },
        {
          label: 'Presentation',
          fieldName: 'conceptPresentation',
        },
      ],
    }
  },

  computed: {
    pages() {
      return [
        this.page - 2,
        this.page - 1,
        this.page,
        this.page + 1,
        this.page + 2,
      ].filter(page => page >= 1 && page <= this.totalPages)
        .sort((a, b) => Math.abs(a - this.page) - Math.abs(b - this.page))
        .slice(0, 3)
        .sort((a, b) => a - b)
    },
  },

  watch: {

  },
  beforeRouteLeave(to, from, next) {
    next()
  },

  async mounted() {
   await this.getCategories()
   await this.updateItems()
  },

  created() {
    this.move = (e) => {
      if (e instanceof MouseEvent && e.buttons !== 1 && e.buttons !== 3){
        this.movingSliderName = ''
      }

      if (this.movingSliderName) {
        let deltaX = 0

        if (e instanceof TouchEvent) {
          deltaX = e.touches[0].screenX - this.sliderStartX
        } else if (e instanceof MouseEvent) {
          deltaX = e.screenX - this.sliderStartX
        }

        const deltaValue = Math.round(deltaX / 100)
        this.ratings[this.movingSliderName] = this.sliderStartValue + deltaValue
        this.ratings[this.movingSliderName] = Math.max(Math.min(this.ratings[this.movingSliderName], 5), 0)
      }

    }

    this.moveEnd = (e) => {
      this.movingSliderName = ''
    }

    window.addEventListener('mousemove',this.move);
    window.addEventListener('touchmove',this.move);

    window.addEventListener('mouseup',this.moveEnd);
    window.addEventListener('touchend',this.moveEnd);
    window.addEventListener('touchcancel',this.moveEnd);
  },
  destroyed() {
    window.removeEventListener('mousemove',this.move);
    window.removeEventListener('touchmove',this.move);

    window.removeEventListener('mouseup',this.moveEnd);
    window.removeEventListener('touchend',this.moveEnd);
    window.removeEventListener('touchcancel',this.moveEnd);
  },

  methods: {
    onSlideStart(field, e) {
      const rect = e.currentTarget.getBoundingClientRect()
      let offset = 0

      if (e instanceof TouchEvent) {
        offset = e.touches[0].clientX - rect.left
        this.sliderStartX = e.touches[0].screenX
      } else if (e instanceof MouseEvent) {
        offset = e.clientX - rect.left
        this.sliderStartX = e.screenX
      }

      this.sliderStartValue = this.ratings[field] = Math.round(offset / 100)

      this.movingSliderName = field
    },

    openLegend() {
      this.showLegend = true
      setTimeout(() => this.$refs.legend?.focus())
    },

    updateAreaFilter(filter) {
      this.areaFilter = filter
      this.updateItems()
    },

    updateSearchText(text) {
      this.search = text
      this.updateItems()
    },

    updateItemsPerPage(value) {
      this.itemsPerPage = value
      this.updateItems()
    },

    updatePage(page) {
      this.page = page
      this.updateItems()
    },

    openApplication(application) {
      //this.disableRating = this.isEvaluated(application)

      this.currentApplication = application
      this.subPage = 'projectEvaluation'
      this.expanded3D = this.expandedDescription = this.expandedPdf = false
      this.ratings = {
        innovationInShapes: 0,
        functionality: 0,
        sustainability: 0,
        technicalFeasibility: 0,
        conceptPresentation: 0,
        comment: '',
      }

      if (this.currentApplication?.presentation?.rates?.length) {
        for (const key in this.ratings) {
          this.ratings[key] = this.currentApplication.presentation.rates[0][key]
        }
      }

    },

    isEvaluated(item) {
      return !!item?.presentation.rates?.length
    },

    wait() {
      return new Promise(resolve => setTimeout(resolve, 2000))
    },

    async submit() {
      if (this.submitting) {
        return
      }

      DataEmitterService.$emit( 'showLoader', true )
      try {
        await ApiRequestService.postRequest(`api/admin-user-competition/rate/${this.currentApplication.id}`, this.ratings)
        await this.updateItems()
        DataEmitterService.$emit( 'showSuccess', true );
        await this.wait()
      } finally {
        this.submitting = false
        DataEmitterService.$emit( 'showLoader', false )
      }
    },

    async updateItems() {
      try {
        let url = `api/admin-user-competition/report?sortBy=id&direction=DESC&count=${this.itemsPerPage}&page=${this.page}`

        if (this.search) {
          url += `&searchText=${encodeURIComponent(this.search)}`
        }

        if (this.areaFilter !== 'All') {
          url += `&applicationStatus=${encodeURIComponent(this.areaFilter)}`
        }

        if (this.selectedCategory) {
          url += `&area=${encodeURIComponent(this.selectedCategory)}`
        }

        const data = await ApiRequestService.getRequest(url)

        this.items = data?.data ?? []
        this.totalElements = data?.totalElements ?? 0
        this.totalPages = data?.totalPages ?? 0
        this.page = data?.currentPage ?? 1

        if (this.page > this.totalPages) {
          this.page = this.totalPages

          if (this.page) {
            await this.updateItems()
          }
        }

      } finally {
        DataEmitterService.$emit( 'showLoader', false );
      }
    },

    async updateCategory(category) {
      this.selectedCategory = category;
      await this.updateItems();
    },

    async getCategories() {
      let url = `api/admin-user-competition/area`;
      const categories = await ApiRequestService.getRequest(url);
      if(categories && categories?.length){
        this.categories = this.categories.concat(categories);
      }
      this.selectedCategory = this.categories[0];
    }
  },
}

